<template>
  <layout>
    <loader
      :object="styling.objectcolor"
      :color1="styling.animationcolor"
      :color2="styling.animationcolor2"
      :size="styling.size"
      :speed="styling.speed"
      :bg="styling.bgcolor"
      :objectbg="styling.objectbg"
      :opacity="styling.opacity"
      :name="selected"
      :disableScrolling="styling.disableScrolling"
    >
    </loader>

    <router-view />
  </layout>
</template>

<script>
import layout from "./components/layout.vue";
import loader from "./lib/loader";
export default {
  data() {
    return {
      selected: "",
      value: 2,
      styling: {
        objectcolor: "#ff9633",
        animationcolor: "#ffffff",
        animationcolor2: "#17fd3d",
        objectbg: "#999793",
        opacity: 80,
        bgcolor: "#343a40",
        size: 5,
        speed: 2,
        disableScrolling: false,
      },
      code: ``,
      flag: false,
    };
  },
  components: {
    layout,
    loader,
  },
  methods: {
    loader() {
      this.selected = "circular";
      this.code = `<loader object="${this.styling.objectcolor}" color1="${this.styling.animationcolor}" color2="${this.styling.animationcolor2}" size="${this.styling.size}" speed="${this.styling.speed}" bg="${this.styling.bgcolor}" objectbg="${this.styling.objectbg}" opacity="${this.styling.opacity}" disableScrolling="${this.styling.disableScrolling}" name="${this.selected}"></loader>`;
      setTimeout(() => {
        this.selected = "";
      }, this.value * 1000);
    },
  },
};
</script>
<style>
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
