<template>
  <header>
    <div class="container">

      <div class="Logo-Section">
        <a href="https://www.phidirect.com" target="_blank">
        <img
          alt="Vue_logo"
          src="http://f.hubspotusercontent00.net/hubfs/8863602/raw_assets/public/phi-direct-theme/images/logo-phi-direct.svg"
        /></a>
      </div>
      <div class="Chat-Section hide">
        <p>CHAT <img alt="Vue logo" src="../assets/Chat-Icon.png" /></p>
      </div>
    </div>
    
  </header>
</template>
