// import { createStore } from "vuex";
// // Modules
// import modules from './modules'

// export default createStore({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: modules,
// });

// Dependencies
import Vuex from 'vuex'


// Modules
import modules from './modules'

// Create Store
export function createStore() {
  return new Vuex.Store({
    modules,
  })
}
export default createStore()