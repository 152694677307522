<template>
   <loader
   :object="styling.objectcolor"
   :color1="styling.animationcolor"
   :color2="styling.animationcolor2"
   :size="styling.size"
   :speed="styling.speed"
   :bg="styling.bgcolor"
   :objectbg="styling.objectbg"
   :opacity="styling.opacity"
   :name="selected"
   :disableScrolling="styling.disableScrolling">
   </loader>

   <!-- <div class="Monthly-Head">
        <div class="container">
          <div class="row">
            <p>
              Monthly Quote Total:
              <span class="monthly-quote-amount">${{ QuoteTotal }}</span>
              &nbsp;<span>(taxes extra)</span>
            </p>
          </div>
        </div>
      </div> -->



  <div class="Form-Section-PHI">
    <h2>Get your PHI Direct Quote</h2>
    <div class="row">
    <form @submit.prevent.stop="submitForm(quote)">

      <div class="col-md-12">
        <div class="row">

          <div class="col-md-3">
            <label>Pet's Name*</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control Input-FieldCH" label="Pet's Name" v-model="quote.name" />
          </div>

          <div class="col-md-3 Display-Hide">
            <label>Pet's Species*</label>
          </div>
          <div class="col-md-8">
            <div class="ToggleMain-Sec">
              <div class="btn-group btn-group-toggle button-sec-toggle Gender-Sec" data-toggle="buttons">
                <label class="btn btn-secondary active btn-sec-Tog">
                  <input type="radio" name="optionsspec" id="Canine_dog" value="42989" autocomplete="off" @change="getbread($event)" checked="" />Dog
                </label>
                <label class="btn btn-secondary btn-sec-Tog">
                  <input type="radio" name="optionsspec" value="42990" id="Feline_cat" @change="getbread($event)" autocomplete="off" />Cat
                </label>
              </div>
            </div>
          </div>

          <div class="col-md-3 Display-Hide"><label>Pet's Gender*</label></div>
          <div class="col-md-8">
            <div class="ToggleMain-Sec">
              <div class="btn-group btn-group-toggle button-sec-toggle Gender-Sec"  data-toggle="buttons">
                <label class="btn btn-secondary active btn-sec-Tog">
                  <input type="radio" name="options" id="gender_male" :selected="quote.Gender==='M'" v-model="quote.Gender" value="M" autocomplete="off" checked=""/>Male
                </label>
                <label class="btn btn-secondary btn-sec-Tog">
                  <input type="radio" name="options" id="gender_female" :selected="quote.Gender==='F'" v-model="quote.Gender" value="F" autocomplete="off"/>Female
                </label>
              </div>
            </div>
          </div>

           <div class="col-md-3"><label>Pet's Breed*</label></div>
          <div class="col-md-8">
            <div class="form-group has-search">
              <i class="fa fa-search"></i>
              <input type="text" class="form-control Input-FieldCH" data-toggle="collapse" data-target="#demo" v-on:keyup="breadsearch();" v-on:keydown="breadsearch();"
                v-on:focus="showlist()" v-model="quote.practice"  :minlength="3" :maxlength="4" placeholder="Search" />
              <div class="practice-list-holder" id="demo" v-if="showDiv && breads.length">
                <ul>
                  <!-- <li v-for="(bread, key) in resultBreeds" :key="key" :value="bread.Id" :title="[bread.Value]" 
                  @click="setBreadcode(bread)"> -->
                  <li v-for="(bread, key) in breads" :key="key" :value="bread.Id" :title="[bread.Value]" @click="setBreadcode(bread)">
                    <a @click="setBreadcode(bread)">{{bread.Value}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-3"><label>Pet's Date of Birth*</label></div>

          <div class="col-md-8">
            <div class="row">
              <div class="col-5 col-md-5 SelectSec-Ch">
                <select class="form-control" id="month" v-model="quote.month" >
                  <option value="0" selected>Month</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>

              <div class="col-5 col-md-5 SelectSec-Ch">
                <select class="form-control" id="year" v-model="quote.year" >
                  <option value="0" selected>Year</option>
                  <option v-for="(year, key) in years" :key="key" :value="year">{{year}}</option>
                </select>
              </div>

              <div class="col-md-4"></div>
              <div class="col-md-12 text-left Birth-TextUN" style="margin-top: 10px">
                <p v-if="showError">
                  We're so sorry, but our insurance does not cover pets over 14
                  years of age. See our Policy for details.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <label>Your Postal Code*</label>
          </div>

          <div class="col-md-8">
            <div class="">
              <div class="col-md-7 Postal-Code">
                <!-- <input type="text" class="form-control Input-FieldCH PostalCode-Error" v-model="quote.zip"  @input="upper($event)"  placeholder="A1A 1A1" pattern="^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$" autocomplete="false"> -->
                <vue-mask 
                  v-model="quote.zip" 
                  id="postal"
                  name="postal"
                  class="form-control Input-FieldCH" 
                  :placeholder="options?.zip?.placeholder || 'A1A 1A1'"
                  :mask="options?.zip?.mask || 'AAA AAA'" 
                  :pattern="options?.zip?.pattern || '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$'"
                  @input="upper($event)" 
                  :options="options"
                > 
                </vue-mask>
              </div>

              <div class="col-md-8"></div>
              <!-- <div class="col-md-12 Postal-Error" v-if="quote.zip==''">
                <p> Postal code required to calculate your premium. </p>
              </div> -->
              <div class="col-md-12 text-left Birth-TextUN PostalCode-ErrorMsg" style="margin-top: 10px">
                <p v-if="showpostalError">
                  We're sorry, but PHI Direct is currently not available in the province of Quebec. Sign up to be notified when we’re available in your area: <strong><a href="https://www.phidirect.com/availability" target="_blank">https://www.phidirect.com/availability</a></strong>
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <label>Your Email*</label>
          </div>

          <div class="col-md-8">
            <input type="email" class="form-control Input-FieldCH" v-model="quote.email" placeholder="" />
          </div>

          <div class="col-md-3">
            <label>Mobile Phone</label>
          </div>
          <div class="col-md-8">
            <vue-mask 
              v-model="quote.phone" 
              id="phone"
              name="phone"
              class="form-control Input-FieldCH" 
              :placeholder="options?.phone?.placeholder"
              :mask="options?.phone?.mask"
              :pattern="options?.phone?.pattern"
              @input="$event" 
              :options="options"
            > 
            </vue-mask>
          </div>

          <!-- <div class="col-md-3">
            <label>Marketing</label>
          </div>
           <div class="col-md-8">
          <div class="form-check check-ext">
          <input
            type="checkbox"
            class="form-check-input"
            id="exampleCheck1"
          />
          <label class="form-check-label" for="exampleCheck1">
           I want up-to-date news, helpful pet information, and special offers from PHI Direct. Unsubscribe at any time. <a href="https://www.phidirect.com/privacy-policy" target="_blank" style="color:#6aa442">Privacy Policy</a>
          </label>
        </div>
           </div> -->
          <br><br><br>
          <div class="row Form-Section-PHI" style="width:100%;margin: 0;">
          <div class="col-md-2"></div>
          <div class="col-md-8  error-field">
           <p v-if="errors.length">
    <b>Please correct the following error(s):</b>
    <ul>
      <li v-for="(errorr, key) in errors" :key="key" style="color:red;text-align: left;">{{ errorr }}</li>
    </ul>
  </p>
  </div>
  <div class="col-md-2"></div>
  </div>

          <div class="col-md-12">
            <div class="btn-Submit">
              <button type="Submit" class="btn btn-primary"> GET YOUR QUOTE</button>
            </div>
          </div>

        </div>
      </div>
    </form>

      <div class="col-md-12 Bot-Text-Terms mt-4 mb-4">
        <p> By clicking Get Your Quote you agree to our<br /><a href="https://www.phidirect.com/terms-of-use-and-regulatory" target="_blank">Terms of Use</a></p>
      </div>
    </div>
  </div>

</template>

<script>
//import api from "../directives/trupanionServices";
//import { actions } from '../store/types';
import axios from "axios";
import { client as config } from '../config';
import moment from 'moment';

// Dependencies
export default {
  name: "quoteForm",
  title: "Get your PHI Direct Quote – Fluff-free Pet Health Insurance for Dogs and Cats",
  props: {
    onSuccess: Function,
  },
  data: () => ({
    options: {
      zip: {
        mask: "AAA AAA",
        placeholder: "A1A 1A1",
        pattern: "^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$",
      },
      phone: {
        mask: "000-000-0000",
        placeholder: "555-555-5555",
        pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
      },
    },
    quote: {month:"0",year:"0",zip:'', phone:''},
    breads: [],
    allbreads: [],
    years:[],
    PetPolicyList:[],
    quoteInfo:{email:'',PostCode:'',petInfo:[], phone:''},
    error: null,
    errors:[],
    showDiv: false,
    loading:false,
    code: ``,
    flag: false,
    selected: '',
    showError:false,
    showpostalError:false,
    value: 2,
    styling: {
      objectcolor: '#ff9633',
      animationcolor: '#ffffff',
      animationcolor2: '#17fd3d',
      objectbg: '#999793',
      opacity: 80,
      bgcolor: '#343a40',
      size: 5,
      speed: 2,
      disableScrolling: false,
    },
    birthDate:{date:'',months:'',birthstatus:false},
    queValue:""
  }),

    computed: {
    resultBreeds(){
      if(this.quote.practice){
      return this.breads.filter((item)=>{
        return this.quote.practice.toLowerCase().split(' ').every(v => item.Value.toLowerCase().includes(v))
      })
      }else{
        return this.breads;
      }
    }
},

  async beforeMount() {
      this.scrollToTop();
      localStorage.removeItem("phone");
      localStorage.removeItem("BuyPolicyData");
      localStorage.removeItem("PetDetails");
      localStorage.removeItem("Email");
      localStorage.removeItem("PaymentAmount");
      localStorage.removeItem("PaymentMethod");
      localStorage.removeItem("InfoDetails");

    if(!localStorage.getItem("PetPolicyList") && !localStorage.getItem("InfoDetails")&& !localStorage.getItem("PaymentAmount") && !localStorage.getItem("BuyPolicyData")){
       this.$router.push({ name: "Home" });
    }
    if (localStorage.getItem("PetPolicyList")) {
      this.$router.push({ name: "quote" });
    }
    if (localStorage.getItem("InfoDetails")) {
      this.$router.push({ name: "info" });
    }
    if (localStorage.getItem("PaymentAmount")) {
      this.$router.push({ name: "payment" });
    }
    if (localStorage.getItem("BuyPolicyData")) {
      this.$router.push({ name: "welcome" });
    }
  if (this.$route.query.Quote!=null) {
      let quoteKey=this.$route.query.Quote;
      let sessionId=this.$route.query.SessionId;
      let objectId=this.$route.query.objectId;
      this.fetchData(quoteKey,sessionId,objectId);
  }
  if(this.$route.query.Quote===null || this.$route.query.Quote===undefined ){
    this.$router.replace({ query: {} })
    this.$router.push({ name: "Home" });
  }
    this.getyears();

   this.quote.PetSpeciedId=42989;
   this.quote.Gender='M';
		const item = {
			"PetSpeciedId":42989,
		}
    this.getall(item);
    this.queValue= this.$cookies.get("hubspotutk");
	},


  methods: {
     scrollToTop() {
                window.scrollTo(0,0);
           },
    upper(e) {
        e.target.value = e.target.value.toUpperCase()
    },

   async submitForm(quote) {
     
      this.errors = [];
      if(!quote.name) this.errors.push("Pet's name required");
      if(quote.practice===undefined) this.errors.push("Pet's breed required or should be selected from dropdown");
      if(quote.practice==="") this.errors.push("Pet's breed required or should be selected from dropdown");
      if(quote.year== "0" && quote.month=="0") this.errors.push("Pet's birth date required");
      if(quote.year!= "0" && quote.month=="0") this.errors.push("Pet's birth month required");
      if(quote.year== "0" && quote.month!="0") this.errors.push("Pet's birth year required");
      if(!quote.zip) this.errors.push("Postal code required to calculate your premium");
      if(quote.email==undefined || quote.email=="") this.errors.push("Please enter your email address");
      if(quote.BreedId!=null && quote.BreedId!=undefined && quote.BreedId!=""&& quote.BreedId!=0) 
      {
       if(quote.zip!=null && quote.zip!=undefined && quote.zip!="")
      {
        quote.zip=quote.zip.replace(/\s/g,'')
         var x =quote.zip.slice(0,3) 
          var xy =quote.zip.slice(3)//quote.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/);
        quote.zip = x+ ' ' + xy;
      }
      if(quote.zip.split("")[0]=="G" && quote.zip!=""){
         this.showpostalError=true;
         this.showError=false;
         this.$toast("We're sorry, but PHI Direct is currently not available in the province of Quebec. Sign up to be notified when we’re available in your area: https://www.phidirect.com/availability",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
      }
      if(quote.zip.split("")[0]=="H" && quote.zip!=""){
        this.showpostalError=true;
        this.showError=false;
         this.$toast("We're sorry, but PHI Direct is currently not available in the province of Quebec. Sign up to be notified when we’re available in your area: https://www.phidirect.com/availability",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
      }
      if(quote.zip.split("")[0]=="J" && quote.zip!=""){
         this.showpostalError=true;
         this.showError=false;
         this.$toast("We're sorry, but PHI Direct is currently not available in the province of Quebec. Sign up to be notified when we’re available in your area: https://www.phidirect.com/availability",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
      }
      var dates='01-'+(quote.month);
      this.birthDate.date=dates+(-quote.year)
     
      this.birthDate.months=quote.month;
     if (quote.email!="" && quote.email!=undefined){
    if (quote.zip!="" && quote.zip!=undefined)
        {
      if(quote.name && quote.practice!=undefined && quote.practice!="" && quote.zip!=null && quote.year!= "0" && quote.month!="0" && quote.email!=undefined && quote.email!="" && quote.zip.split("")[0]!="G"
      && quote.zip.split("")[0]!="H" && quote.zip.split("")[0]!="J"){
      if(this.checkBirthday((this.birthDate))){
      if (this.birthDate.birthstatus===true) {
      
      try {
        
          this.loading = true;
      this.loader();
        this.showpostalError=false;
        this.showError=false;
       
        var mnth=(parseInt(quote.month));
        var dateMonth=(-mnth)+'-01';
        var bdates=(quote.year+dateMonth+'T00:00:00+00:00');
         let petinfo={BirthDate:bdates,BreedId:quote.BreedId,Gender:quote.Gender,PetName:quote.name,SpeciesId :quote.PetSpeciedId};
        // petinfo.BirthDate= bdates;
        // petinfo.BreedId=quote.BreedId;
        // petinfo.Gender=quote.Gender;
        // petinfo.PetName=quote.name;
        // petinfo.SpeciesId= quote.PetSpeciedId;

        

        let Postmodel = {
          phone: quote.phone,
          email: quote.email,
          PostCode: quote.zip,
          petInfo: [petinfo],
          savedQuoteKey: "",
          cookieValue: this.queValue,
          objectId: this.objectId
        }
        let url= config.api.policyUrl+'GetQuoteValues';

      const response = await axios.post(url,Postmodel);
      if(response.data.result){
        if(response.data.petPolicyList.length>0)
        {
          this.PetPolicyList=response.data.petPolicyList;
          this.PetPolicyList.forEach(policy=>{
            policy.policyList[0].selected=true;
            policy.recurringMonthly= policy.policyList[0].recurringMonthly;
          })
          this.quoteInfo.email=quote.email?quote.email:"";
          this.quoteInfo.PostCode=quote.zip;
          this.quoteInfo.petInfo=response.data;
          this.objectsIds=this.quoteInfo.petInfo.responseId;
          localStorage.setItem("ObjectId", JSON.stringify(this.objectsIds));
          localStorage.setItem("PetPolicyList", JSON.stringify(this.quoteInfo));
          localStorage.setItem("PostalCode", JSON.stringify(this.quoteInfo.PostCode));
          localStorage.setItem("Email", JSON.stringify(this.quoteInfo.email));

          localStorage.setItem('phone', quote?.phone)

          this.$cookies.set("hubObjectId", this.objectsIds, "expiring time");
          this.$router.push({name: 'quote'});
        }
      }
      else{
            this.birthDate.birthstatus=false;
            this.$toast("We're sorry, but pets must be at least 7 weeks of age at the time of enrollment",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
          }
      

     } catch (error) {
        if (error && error.response && error.response.status === 401) {
          this.isLogged = false;
           this.$toast(error.response,{
           styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        } else {
          this.error = error.message;
          this.$toast(error.message,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        }
      } finally {
        this.loading = false;
      }
      }
        }
      }
      }
      else  
      {
         this.showError=false;
         this.$toast("Postal code required to calculate your premium",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
      }
      }
      else  
      {
         this.showError=false;
         this.$toast("Please enter your email address",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
      }
   }
   else{
      this.showError=false;
         this.$toast("Pet's breed required or should be selected from dropdown",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
    }
    },

    checkBirthday(year,monthss){
      year=this.birthDate.date;
      monthss=parseInt(this.birthDate.months);
      const datee = year.toString();
      var dateofvisit = moment(datee, 'DD-MM-YYYY');
      var today = moment();
      var age=today.diff(dateofvisit, 'years');
      const currentMonth=moment().month()+1;
      const months=currentMonth-monthss;
      this.birthDate.birthstatus=false;

      if (age===0) {
        if(months>0 && months<2){
            if (months>2) {
              this.birthDate.birthstatus=true;
               return this.birthDate.birthstatus;
            }
        }
        }
       if(age >= 0 && age <=14 ){
            if(age==14){
              if (months<=0) {
                 this.showError=false;
                  this.birthDate.birthstatus=true;
                 return this.birthDate.birthstatus;
              }
            }
            if(age<14){
               this.showError=false;
                this.birthDate.birthstatus=true;
               return this.birthDate.birthstatus;
            }
            else{
              this.showError=true;
              this.showpostalError=false;
               this.birthDate.birthstatus=false;
            this.$toast("We're sorry, but pets must be under 14 years of age at the time of enrollment",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
            }
          }
          else{
             this.showError=true;
             this.showpostalError=false;
              this.birthDate.birthstatus=false;
            this.$toast("We're sorry, but pets must be under 14 years of age at the time of enrollment",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
          }
        },
    getyears(){
      let currentYear= new Date().getFullYear();
      this.years=[];
      let i = 0
      for(i = 0; i < 15; i++) {
        let year= currentYear-i;
        this.years.push(year);
      }
    },

    getbread(event){
      this.quote.practice="";
      let SpeciedId = event.target.value;
      this.quote.PetSpeciedId= JSON.parse(SpeciedId);
      const item = {
		   	"PetSpeciedId":this.quote.PetSpeciedId
		  }
      this.getall(item);
    },

    async getall(search) {
      this.loading = true;
      // this.loader();
      
      try {
        debugger;
        let url= config.api.url+'BreedSearch';
        const response = await axios.post(url,search);
        const results = response.data.list;
        this.breads= results.map(post => ({
          Value:post.value,
          BrandId: post.brandId,
          Excluded: post.excluded,
          ExcludedNarrative: post.excludedNarrative,
          Id: post.id,
          MaxAge: post.maxAge,
          MaxAgeWeeks: post.maxAgeWeeks,
          MinAge: post.minAge,
          MinAgeWeeks: post.minAgeWeeks,
          SpeciesId:post.speciesId,
        }))

        this.allbreads=this.breads;

      } catch (error) {
        if (error && error.response && error.response.status === 401) {
          this.isLogged = false;
          this.allbreads=[];
          this.$toast(error.message,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        } else {
          this.error = error.message;
          this.allbreads=[];
          this.$toast(error.message,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        }
      } finally {
        this.loading = false;
      }
    },

    setBreadcode(bread){
     this.quote.BreedId=bread.Id;
     this.quote.practice=bread.Value;
     this.showDiv = false;
    },

    breadsearch() {
		  if(this.quote.practice.length >=1)
		  {
        const item = {
			    "PetSpeciedId":this.quote.PetSpeciedId,
          "Filter":this.quote.practice
		    }
        this.getall(item);
      }
      else{
         const item = {
			    "PetSpeciedId":this.quote.PetSpeciedId,
          "Filter":this.quote.practice
		    }
        this.getall(item);
      }
    },

    showlist() {
      this.breads;
      this.showDiv = true;
    },
    hidelist() {
      this.showDiv = false;
    },
    loader() {
      this.selected='circular';
      this.code=`<loader object="${this.styling.objectcolor}" color1="${this.styling.animationcolor}" color2="${this.styling.animationcolor2}" size="${this.styling.size}" speed="${this.styling.speed}" bg="${this.styling.bgcolor}" objectbg="${this.styling.objectbg}" opacity="${this.styling.opacity}" disableScrolling="${this.styling.disableScrolling}" name="${this.selected}"></loader>`
      setTimeout(() => {
        this.selected='';
      }, this.value*3000);
    },

    computed: {
      filterBreadByName: function () {
        return this.breads.filter((bread) => !bread.Value.indexOf(this.quote.practice));
      },
    },

  async fetchData(savedQuoteKey,sessionId,objectId){
   try
      {
        this.loader(); 
        localStorage.removeItem("PetPolicyList");
        localStorage.removeItem("PostalCode");
        localStorage.removeItem("Email");
        this.objectsIds=this.$cookies.get("hubObjectId");
        if (objectId==null||objectId==undefined) {
          objectId=0;
        }
        let sessionIds=parseInt(sessionId)
        let objectIds=parseInt(objectId)
        let url= config.api.policyUrl+'GetQuoteValuesByQueryString';
        console.log(url);
        let postModel={QuoteKey:savedQuoteKey, SessionId:sessionIds,cookieValue:this.queValue,objectId: objectIds}
        const response = await axios.post(url,postModel)
        if(response.data.result){
        if(response.data.petPolicyList.length>0)
          {
          this.PetPolicyList=response.data.petPolicyList;
          var dataArr = response.data.responseId.split(',');
          this.quoteInfo.PostCode=dataArr[0];
          this.quoteInfo.email=dataArr[1]?dataArr[1]:"";
          this.objectId=dataArr[2]?dataArr[2]:"";
          this.quoteInfo.petInfo=response.data;
          this.PetPolicyList.forEach(policy=>{
            policy.policyList[0].selected=true;
            policy.recurringMonthly= policy.policyList[0].recurringMonthly;
          })
          localStorage.setItem("PetPolicyList", JSON.stringify(this.quoteInfo));
          localStorage.setItem("PostalCode", JSON.stringify(this.quoteInfo.PostCode));
          localStorage.setItem("Email", JSON.stringify( this.quoteInfo.email));
          localStorage.setItem("ObjectId", JSON.stringify( this.objectId));
          this.$router.push({name: 'quote'});
          }
          else{
        this.$toast("We’re so sorry, but your quote is only valid for 30 days. Please restart your pet’s quote",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
        }
        }
       else{
        this.$toast("We’re so sorry, but your quote is only valid for 30 days. Please restart your pet’s quote",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
        }
      } catch (error) {
        if (error && error.response && error.response.status === 401) {
          this.isLogged = false;
          this.$toast(error.response,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        } else {
          this.error = error.message;
          this.$toast(error.message,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        }
      } finally {
        this.loading = false;
      }
 }
  },
};
</script>
