<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <p>
            <a href="https://www.phidirect.com" target="_blank">
              <img src="../assets/phi-logo-footer.svg" style="width: 9rem"
            /></a>
          </p>
          <ul>
            <li>
              <a
                href="https://www.phidirect.com/hubfs/PHI-Direct-Documents/PHI-CA-Policy-Document-Online.pdf"
                target="_blank"
                >Sample Policy</a
              >
            </li>
            <li>
              <a href="https://www.phidirect.com/privacy-policy" target="_blank"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a
                href="https://www.phidirect.com/terms-of-use-and-regulatory"
                target="_blank"
                >Terms of Use</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-8">
          <p>
            Coverage subject to terms and conditions, applicable waiting
            periods, deductions and co-insurance, policy limits and exclusions.
            Always refer to your insurance policy for information about your
            coverage. Pre-existing conditions that occur prior to the expiry of
            the applicable policy waiting periods are excluded from coverage.
          </p>

          <p>
            Conditions once treated or identified in a given policy year are
            excluded from coverage in future policy years.
          </p>

          <p>
            In case of discrepancy, the insurance contract prevails over general
            information available on this site. Any policy related changes made
            on this site will not be effective until you receive confirmation.
            Individual circumstances may vary. You may wish to contact one of
            our licensed insurance advisors if you need advice about your
            insurance needs.
          </p>
          <p>
            © {{ new Date().getFullYear() }} All rights reserved. PHI Direct pet insurance products are underwritten by Omega General Insurance Company and are sold and distributed by Canada Pet Health Insurance Services, Inc., dba PHI Direct. PHI Direct is available in all provinces and territories except Quebec. PHI Direct, the PHI Direct Design, "Fluff-free Pet Health Insurance" and related words and slogans are trademarks used under license.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
