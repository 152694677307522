<template>
  <div class="fixed-top">
    <Header />

    <div class="Home-BackCh">
      <div class="container">
        <div class="row"></div>
      </div>
    </div>
    <!-- <div id="tophead" v-if="showheadDiv">

      <div class="header-bot-sec">
        <div class="container">
          <div class="row">
            <form action="#">
              <p>
                <input
                  type="radio"
                  id="quote"
                  name="radio-group"
                  :checked="isQuote"
                  disabled
                />
                <label for="quote" v-bind:class="{ active: isQuote }"
                  ><span>QUOTE</span></label
                >
              </p>
              <p>
                <input
                  type="radio"
                  id="userinfo"
                  name="radio-group"
                  :checked="isinfo"
                  disabled
                />
                <label for="userinfo" v-bind:class="{ active: isinfo }"
                  ><span>YOUR INFO</span></label
                >
              </p>
              <p>
                <input
                  type="radio"
                  id="payment"
                  name="radio-group"
                  :checked="ispayment"
                  disabled
                />
                <label for="payment" v-bind:class="{ active: ispayment }"
                  ><span>PAYMENT</span></label
                >
              </p>
              <p>
                <input type="radio" id="wellcome" name="radio-group" disabled />
                <label for="wellcome"><span>WELCOME</span></label>
              </p>
            </form>
          </div>
        </div>
      </div>
      <div class="Monthly-Head">
        <div class="container">
          <div class="row">
            <p>
              Monthly Quote Total:
              <span class="monthly-quote-amount">${{ QuoteTotal }}</span>
              <span>(taxes extra)</span>
            </p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <slot />
  <Footer />
</template>

<script>
import Header from "./header.vue";
import Footer from "./footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data: () => ({
    error: null,
    isQuote: false,
    isinfo: false,
    ispayment: false,
    showheadDiv: false,
    iswelcome: false,
    QuoteTotal: 0,
  }),
  mounted() {
    var currentUrl = window.location.pathname;
    if (currentUrl == "/") {
      this.showheadDiv = false;
    }
    if (currentUrl == "/quote") {
      this.showheadDiv = true;
      this.isinfo = false;
      this.ispayment = false;
      this.isQuote = true;
    }

    if (currentUrl == "/info") {
      this.showheadDiv = true;
      this.isQuote = false;
      this.ispayment = false;
      this.isinfo = true;
    }
    if (currentUrl == "/payment") {
      this.showheadDiv = true;
      this.isQuote = false;
      this.isinfo = false;
      this.ispayment = true;
    }
    if (currentUrl == "/welcome") {
      this.showheadDiv = true;
      this.isQuote = false;
      this.isinfo = false;
      this.ispayment = false;
      this.iswelcome = true;
    }

    //this.getQuotetotal();
  },
  methods: {
    //   getQuotetotal() {
    //     let getPolicyValue = localStorage.getItem("PetPolicyList");
    //     let quoteInfo = JSON.parse(getPolicyValue);
    //     let policeslist = quoteInfo.petInfo.petPolicyList;
    //     //let QuoteTotal=0;
    //     policeslist.forEach((policy) => {
    //       //policy.policyList[0].selected=true;
    //       //policy.recurringMonthly= policy.policyList[0].recurringMonthly;
    //       this.QuoteTotal += policy.policyList[0].recurringMonthly;
    //     });
    //   },
  },
};
</script>
