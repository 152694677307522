import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./index.css";
import loader from "vue-ui-preloader";
import DKToast from 'vue-dk-toast';
import Dropdown from 'vue-simple-search-dropdown';
import titleMixin from '../titleMixin';
import VueCookies from 'vue3-cookies';
import VueMask from 'vue-jquery-mask';



// Create App
createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(loader)
  .use(DKToast)
  .use(Dropdown)
  .use(VueCookies)
  .mixin(titleMixin)
  .use(VueMask)
  .mount("#app");


