// Content
export const actions = {
    LOAD_BREAD: "LOAD_BREAD",
    UNLOAD_BREAD: "UNLOAD_BREAD",
    LOAD_ITEM: "LOAD_ITEM",
    UNLOAD_ITEM: "UNLOAD_ITEM",
    SET_LOADING: "SET_LOADING",
    SET_SEARCH: "SET_SEARCH",
    SET_SORT_FIELD: "SET_SORT_FIELD",
}

// Model
//export const UNLOAD_MODEL = "UNLOAD_MODEL";
//export const UNLOAD_MODELS = "UNLOAD_MODELS";

export const types = {
    actions,
}

export function getTypes(namespace) {

    const _types = {}
    for (const type in types) {
        if (Object.prototype.hasOwnProperty.call(types, type)) {
            _types[type] = {}
            for (const key in types[type]) {
                if (Object.prototype.hasOwnProperty.call(types[type], key)) {
                    const val = types[type][key]
                    _types[type][key] = val.replace(`${namespace}/`, '')
                }
            }
        }
    }
    return _types
}

export default types