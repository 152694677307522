// Dependencies
//import Vue from "vue";
//import * as types from "../types";
import api from "../../directives/trupanionServices";
import { getTypes } from '../types'

const types = getTypes('Object')

// State
const state = {
  quotes: [],
  breads: [],
  pats: [],
  item: {},
  page: {
    current: 1,
    limit: 50,
    count: 1,
  },
  search: {
    keyword: null,
    keys: [],
    sort: "createdAt",
  },
  sort: {
    field: "createdAt",
    reverse: true,
  },
  loading: 0,
};

// Actions
const actions = {

  [types.actions.LOAD_BREAD]({ commit }, params) {

    commit(types.SET_LOADING, true);
    const request = api.GetBreads("SearchService/BreedSearch", params);

    // return async function () {
    //   this.breads = await api.GetBreads("SearchService/BreedSearch", params);
    // };
    request.then(res => {
      // debugger
      commit(res)
    }).catch(err => {
      //error[key] = err
      commit(types.mutations.SET_ERROR, err, { root: true })
    }).then(() => {
      //commit(types.mutations.SET_LOADING, false, { root: true })

    })
    return request


  },


  // loadBreads: ({ rootState, commit }, data) => {
  //   debugger;
  //   commit(types.SET_LOADING, true);
  //   return async function () {
  //     let path = "";
  //     this.breads = await api.getlist(path, data);
  //   };
  // },
  // loadPetSp: ({ rootState, commit }, key) => {
  //   commit(types.SET_LOADING, true);
  //   // return new Promise((resolve, reject) => {
  //   //   contentRef.once("value").then((snap) => {
  //   //     commit(types.SET_LOADING, false);
  //   //     resolve(fb.prepOut(snap, true));
  //   //   });
  //   //});
  // },
  // getquote: ({ rootState, commit }, data) => {
  //   commit(types.SET_LOADING, true);
  //   // return new Promise((resolve, reject) => {
  //   //   contentRef.once("value").then((snap) => {
  //   //     commit(types.SET_LOADING, false);
  //   //     resolve(fb.prepOut(snap, true));
  //   //   });
  //   // });
  // },
};

// Mutations
// const mutations = {
//   [types.UNLOAD_CONTENT](state) {
//     state.content = [];
//   },
//   [types.UNLOAD_ITEM](state) {
//     state.item = {};
//   },
//   [types.SET_LOADING](state, loading) {
//     if (loading) {
//       state.loading++;
//     } else {
//       state.loading--;
//     }
//     Vue.set(state, "loading", state.loading);
//   },

//   [types.SET_SEARCH](state, search) {
//     state.search.keys = search.keys;
//     if (search.keyword.length < 3) {
//       state.search.keyword = "";
//     } else {
//       state.search.keyword = search.keyword;
//     }
//   },
//   [types.SET_SORT_FIELD](state, field) {
//     if (state.sort.field === field) {
//       state.sort.reverse = !state.sort.reverse;
//     } else {
//       state.sort.field = field;
//       state.sort.reverse = false;
//     }
//   },
// };


// Module
export const module = {
  namespaced: true,
  state,
  actions
}
