<template>
  <loader :object="styling.objectcolor" :color1="styling.animationcolor" :color2="styling.animationcolor2"
    :size="styling.size" :speed="styling.speed" :bg="styling.bgcolor" :objectbg="styling.objectbg"
    :opacity="styling.opacity" :name="selected" :disableScrolling="styling.disableScrolling">
  </loader>
  <div class="fixed-top">
    <Header />
    <div id="tophead" v-if="showheadDiv">
      <div class="header-bot-sec">
        <div class="container">
          <div class="row">
            <div class="rdo">
              <form action="#">
                <p class="first-rasecc">
                  <input type="radio" id="quote" name="radio-group" :checked="isQuote" disabled />
                  <label for="quote" v-bind:class="{ active: isQuote }"><span>QUOTE</span></label>
                </p>
                <p class="first-rasecc1">
                  <input type="radio" id="userinfo" name="radio-group" :checked="isinfo" disabled />
                  <label for="userinfo" v-bind:class="{ active: isinfo }"><span>YOUR INFO</span></label>
                </p>
                <p class="first-rasecc2">
                  <input type="radio" id="payment" name="radio-group" :checked="ispayment" disabled />
                  <label for="payment" v-bind:class="{ active: ispayment }"><span>PAYMENT</span></label>
                </p>
                <p class="first-rasecc3">
                  <input type="radio" id="wellcome" name="radio-group" disabled />
                  <label for="wellcome"><span>WELCOME</span></label>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="Monthly-Head">
        <div class="container">
          <div class="row">
            <p>
              Monthly Quote Total:
              <span class="monthly-quote-amount">${{ QuoteTotal.toFixed(2) }}</span>
              &nbsp;<span>(taxes extra)</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <slot />
  <!-- <Footer /> -->
  <form>
    <div class="container Plan-Section-Page">

      <div class="row">
        <div class="accordion quote-page" id="accordionExample">
          <div v-for="(policy, key) in policies" :key="key" class="accordion-item card">
            <div class="accordion-header card-header" id="headingOne">

              <a :class="key < policies.length-1 ?'accordion-toggle in collapsed':'accordion-toggle in'"
                data-bs-toggle="collapse" :data-bs-target="'#collapseOne-'+key" aria-expanded="true"
                aria-controls="collapseOne">

                <i class="fas fa-caret-right icon-updown rotate-icon"></i>
                <h5 class="mb-0"> {{policy.petInfo.petName}}<br /><span v-for="(policyitem, key) in policy.policyList"
                    :key="key"><span v-if="policyitem.selected==true">${{policyitem.recurringMonthly.toFixed(2)}} /
                      month</span></span></h5>
                <div class="remove-sec"> <i class="fas fa-times Close-Sec-Btn"
                    @click="removeFromList(key)"></i><span>REMOVE</span></div>
              </a>

            </div>
            <div :id="'collapseOne-'+key"
              :class="key < policies.length-1 ? 'accordion-collapse collapse' : 'accordion-collapse collapse show'"
              aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="plan-select-card-body"><br class="plan-select-br">
                <h3>Choose your plan:</h3>

                <div class="row Plan-Section-BoxInnerMain">
                  <div class="Plan-Section-InnerBox" v-for="(plan, index) in policy.policyList.slice().reverse()"
                    :key="index">
                    <div class="col-6 col-md-6 Plan-Section-BoxInner" v-bind:class="{ test: plan.selected }"
                      @click="setPlan(plan.productId,key)" :style="policyListStyle(index)">
                      <h4>{{plan.productName.split(" ")[0].toUpperCase()}}
                        <span>{{plan.productName.split(" ")[1]}}</span>
                      </h4>
                      <h6><span>${{plan.recurringMonthly.toFixed(2)}}</span><br /><sub>PER MONTH</sub></h6>

                      <p class="recommended"><strong v-if="index===1">MOST POPULAR</strong></p>

                      <p class="direct-amount number-font text-shadow">${{ plan.productName==="Direct 5"?
                        "5,000":"10,000" }}</p>
                      <p class="text-shadow annual-benefits">Annual Policy Limit*</p>
                      <!-- <p class="bg-button-alpha">As low as $10/month cats  $14/month dogs*</p> -->
                      <p class="Slect-Right" v-if="plan.selected"> <i class="fas fa-check"></i>SELECTED </p>
                    </div>
                  </div>

                </div>

                <div class="Plan-Desc">
                  <p class="nec">* $200 deductible / 80% reimbursement on all plans.</p>
                  <p>
                    Your monthly payment will reflect any applicable premium
                    taxes.<br />
                    <!-- There is also a one-time enrollment fee per pet of $35 plus
                    applicable taxes. -->
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div class="accordion-item card">
            <div class="accordion-headerr card-header" id="headingThree3">
              <a class="accordion-toggle-plus in collapsed" type="" data-bs-toggle="collapse"
                data-bs-target="#collapseThree3" aria-expanded="false" aria-controls="collapseThree3">
                <h5 class="mb-0">Add Another Pet <br>
                   <span class="multi-pt"> (5% multi-pet discount)</span>
                  </h5>
                  <i class="fa fa-plus rotate-icon" aria-hidden="true"></i>
               
              </a>
            </div>
            <div id="collapseThree3" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <form @submit.prevent.stop="submitForm(quote)">
                <div class="card-body Add-Another-PetSecMain">
                  <div class="container">
                    <div class="row Add-Another-PetSec">
                      <div class="col-md-4"><label>Pet's Name*</label></div>

                      <div class="col-md-6">
                        <input type="text" class="form-control Input-FieldCH" label="Pet's Name" v-model="quote.name" />
                      </div>

                      <div class="col-md-4 Display-Hide">
                        <label>Pet's Species*</label>
                      </div>

                      <div class="col-md-8">
                        <div class="ToggleMain-Sec Accordine-ToggleSwitch">
                          <div class="btn-group btn-group-toggle button-sec-toggle Gender-Sec" data-toggle="buttons">
                            <label class="btn btn-secondary active btn-sec-Tog">
                              <input type="radio" name="options1" id="Canine_dog" value="42989"
                                :selected="quote.PetSpeciedId==='42989'" autocomplete="off" @change="getbread($event)"
                                checked="" /> Dog
                            </label>
                            <label class="btn btn-secondary btn-sec-Tog">
                              <input type="radio" name="options1" value="42990" id="Feline_cat"
                                :selected="quote.PetSpeciedId==='42990'" @change="getbread($event)"
                                autocomplete="off" /> Cat
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 Display-Hide">
                        <label>Pet's Gender*</label>
                      </div>

                      <div class="col-md-8">
                        <div class="ToggleMain-Sec Accordine-ToggleSwitch">
                          <div class="btn-group btn-group-toggle button-sec-toggle Female-Sec Gender-Sec"
                            data-toggle="buttons">
                            <label class="btn btn-secondary active btn-sec-Tog">
                              <input type="radio" name="options" id="gender_male" :selected="quote.Gender==='M'"
                                v-model="quote.Gender" value="M" autocomplete="off" checked="" />
                              Male
                            </label>
                            <label class="btn btn-secondary btn-sec-Tog">
                              <input type="radio" name="options" id="gender_female" :selected="quote.Gender==='F'"
                                v-model="quote.Gender" value="F" autocomplete="off" />
                              Female
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <label>Additional Pet's Breed*</label>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group has-search">
                          <i class="fa fa-search"></i>
                          <input type="text" class="form-control Input-FieldCH" data-toggle="collapse"
                            data-target="#demo" v-on:keyup="breadsearch()" v-on:keydown="breadsearch()"
                            v-on:focus="showlist()" v-on:hover="showlist()" v-model="quote.practice" :minlength="3"
                            :maxlength="4" placeholder="Search" />
                          <div class="practice-list-holder" id="demo" v-if="showDiv && breads.length">
                            <ul>
                              <li v-for="(bread, key) in breads" :key="key" :value="bread.Id" :title="[bread.Value]"
                                @click="setBreadcode(bread)">
                                <a @click="setBreadcode(bread)">{{bread.Value}}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <label>Additional Pet's Date of Birth*</label>
                      </div>

                      <div class="col-md-8">
                        <div class="row">
                          <div class="col-md-4 SelectSec-Ch">
                            <select class="form-control" id="month" v-model="quote.month">
                              <option value="0" selected>Month</option>
                              <option value="01">01</option>
                              <option value="02">02</option>
                              <option value="03">03</option>
                              <option value="04">04</option>
                              <option value="05">05</option>
                              <option value="06">06</option>
                              <option value="07">07</option>
                              <option value="08">08</option>
                              <option value="09">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                          </div>
                          <div class="col-md-4 SelectSec-Ch">
                            <select class="form-control" id="year" v-model="quote.year">
                              <option value="0" selected>Year</option>
                              <option v-for="(year, key) in years" :key="key" :value="year">{{year}}</option>
                            </select>
                          </div>
                          <div class="col-md-4"></div>
                        </div>
                      </div>

                      <br><br><br>

                      <div class="col-md-2"></div>
                      <div class="col-md-8 error-field">
                        <p v-if="errors.length">
                          <b>Please correct the following error(s):</b>
                        <ul>
                          <li v-for="(errorr, key) in errors" :key="key" style="color:red;text-align: left;">{{ errorr
                            }}</li>
                        </ul>
                        </p>
                      </div>
                      <div class="col-md-2"></div>
                      <div class="col-md-12">
                        <p class="ADD-ConBtn">
                          <button type="Submit" class=""> ADD YOUR QUOTE</button>
                        </p>
                        <p class="ADD-Cancel"><a data-toggle="collapse" data-parent="#accordionEx1"
                            href="#collapseThree3" aria-expanded="false" aria-controls="collapseThree3"
                            v-on:click="clearErrors">CANCEL</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row choice-and-click-continue">
        <h4>Please select your plan and click continue.</h4>
        <p class="Plan-ConBtn"><a @click="continuetonext()">CONTINUE</a></p>
      </div>
    </div>
  </form>

  <div class="Not-Quote-FootSec">
    <div class="container">
      <div class="row">
        <form @submit.prevent.stop="saveQuote()">
          <div class="Not-Quote-FootSecInner">
            <h2>Not quite ready to buy Direct?</h2>

            <h3>Save & Send Your Quote</h3>

            <p class="Foot-TextMain">
              We can send you a link to your quote by email. All cost estimates are valid for 30 days, After that time,
              pricing may be updated when returning to view the quote or complete
              enrollment.
            </p>

            <!-- <div class="ToggleMain-Sec hide">
              <div class="btn-group btn-group-toggle button-sec-toggle" data-toggle="buttons">
                <label class="btn btn-secondary active btn-sec-Tog">
                  <input type="radio" name="options" id="option1" @click="setEmail('Email')" autocomplete="off"
                    checked="" />Email</label>
                <label class="btn btn-secondary btn-sec-Tog">
                  <input type="radio" name="options" id="option2" @click="setEmail('Phone')" autocomplete="off"
                    disabled /> SMS
                </label>
              </div>
            </div> -->

            <div class="Mobile-sec-Ch" style="margin: 15px 0px;">
              <label>Email to: {{emails}}</label>
            </div>

            <!-- <div class="Mobile-sec-Ch hide">
              <label>Email to: (Required)</label>
              <input type="email" class="form-control Input-FieldCH" v-model="savequote.email" placeholder=""
                disabled />
            </div>

            <div class="Mobile-sec-Ch hide" v-if="!savequote.IsEmail">
              <label>Mobile Phone: (Required)</label>
              <input type="text" class="form-control Input-FieldCH" v-model="savequote.phone" placeholder="" />
            </div> -->

            <div class="btn-Submit">
              <!-- <div class="form-check check-ext">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="quote.consent1"
                  @change="onChangeChekBox($event)" />
                <label class="form-check-label" for="exampleCheck1" style="color:#fff;text-align: left;">
                  I want up-to-date news, helpful pet information, and special offers from PHI Direct by email.
                  Unsubscribe at any time.
                  <a href="https://www.phidirect.com/privacy-policy" target="_blank"
                    style="color:#fff;text-decoration: underline;">Privacy policy</a>
                </label>
              </div> -->
              <button type="submit" class="btn btn-primary" style="cursor:pointer">SAVE & SEND</button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { client as config } from '../config';
import Header from "../components/header.vue";
import moment from 'moment';

export default {
   components: {
    Header,
  },
  name: "quoteDetailsForm",
  title: "Get PHI Direct – Your Quote",
  props: {
    onSuccess: Function,
  },
  data: () => ({
    //error: null,
    isQuote: true,
    isinfo:false,
    ispayment:false,
    showheadDiv: true,
    QuoteTotal: 0,
    savedQuoteKey:'',
    quote: {month:"0",year:"0",consent1:false},
    policies: [],
    errors:[],
    quoteInfo:{},
    quoteRequest:{email:'',PostCode:'',savedQuoteKey:'',petInfo:[]},
    savequote:{email:'',PostCode:'',phone:'', savedQuoteKey:'',IsEmail:true},
    breads: [],
     Questions: [],
    allbreads: [],
    error: null,
    showDiv: false,
    IsEmail: true,
    code: ``,
    flag: false,
    selected: '',
    value: 2,
    styling: {
      objectcolor: '#ff9633',
      animationcolor: '#ffffff',
      animationcolor2: '#17fd3d',
      objectbg: '#999793',
      opacity: 80,
      bgcolor: '#343a40',
      size: 5,
      speed: 2,
      disableScrolling: false,
    },
    birthDate:{date:'',months:'',birthstatus:false},
     PostalCodes:'',
     mail:'',
     emails:'',
    pet1_id: "",
    pet1_name: "",
    pet1_species: "",
    pet2_id: "",
    pet2_name: "",
    pet2_species: "",
    pet3_id: "",
    pet3_name: "",
    pet3_species: "",
    pet4_id: "",
    pet4_name: "",
    pet4_species: "",
    pet5_id: "",
    pet5_name: "",
    pet5_species: "",
    objectId:0,
     BirthDate: "",
      BreedId: 0,
      Gender: "",
      PetName: "",
      SpeciesId: "",
      petsInfo:[],
      petsInfos:[],
      petsDetails:[]
  }),

  
  async beforeMount() {
     
    if(!localStorage.getItem("PetPolicyList")) {
      this.$router.push({ name: "Home" });
    }
    
    let emaill = localStorage.getItem("Email");
    let emailss = JSON.parse(emaill);
    this.emails=emailss;
    this.savequote.email=this.emails;
    this.mail=emailss;
    
    if(localStorage.getItem("ObjectId")!=null){
    let objectIds = localStorage.getItem("ObjectId");
    let objectidss = JSON.parse(objectIds);
    this.objectsIds=objectidss;
    }


     this.scrollToTop();
     this.getQuotetotal();

     let postCode=localStorage.getItem("PostalCode");
      this.PostalCodes = JSON.parse(postCode);

    if (!localStorage.getItem("PetPolicyList")) {
      this.$router.push({ name: "Home" });
    }
    let getPolicyValue = localStorage.getItem("PetPolicyList");
    this.quoteInfo = JSON.parse(getPolicyValue);
    let quotekey= this.quoteInfo.petInfo.savedQuoteKey;
    this.savedQuoteKey= quotekey;
    let policeslist = this.quoteInfo.petInfo.petPolicyList;
    policeslist.forEach(policy =>{ 
      policy.recurringMonthly= policy.policyList[0].recurringMonthly;
      this.petsDetails.push(policy);
    });
    this.policies= policeslist;
    this.quoteRequest.email=this.quoteInfo.email;
    this.quoteRequest.PostCode=this.PostalCodes;
    this.quoteRequest.savedQuoteKey=this.quoteInfo.petInfo.savedQuoteKey;
    this.savequote.email=this.quoteInfo.email;
    this.savequote.PostCode=this.PostalCodes;
    this.getyears();

    this.quote.PetSpeciedId=42989;
    this.quote.Gender='M';
		const item = {
			"PetSpeciedId":42989, 
		}
    this.getall(item);
    this.getQuestions();

    this.queValue= this.$cookies.get("hubspotutk");
    this.$cookies.set("hubObjectId", this.objectsIds, "expiring time");
  },
  mounted() {
  },

  methods: {

     onChangeChekBox(event) {
      if(event.target.checked===true){
         this.quote.consent1=true;
      }
      else{
         this.quote.consent1=false;
       }
    },

  policyListStyle: function(i) {
      var style = {};
      if (i>0) {
        style.cursor = 'pointer';
      }
      if(i<=0){
        style.cursor = 'pointer';
      }
      return style;
    },

   scrollToTop() {
                    window.scrollTo(0,0);
                 },
                 
  async submitForm(quote) {
    this.errors = [];
      if(!quote.name) this.errors.push("Pet's name required");
      if(quote.practice===undefined) this.errors.push("Pet's breed required or should be selected from dropdown");
      if(quote.practice==="") this.errors.push("Pet's breed required or should be selected from dropdown");
      if(quote.year== "0" && quote.month=="0") this.errors.push("Pet's birth date required");
      if(quote.year!= "0" && quote.month=="0") this.errors.push("Pet's birth month required");
      if(quote.year== "0" && quote.month!="0") this.errors.push("Pet's birth year required");
      if(quote.BreedId!=null && quote.BreedId!=undefined && quote.BreedId!=""&& quote.BreedId!=0) 
      {
      
      var dates='01-'+(quote.month);
      this.birthDate.date=dates+(-quote.year)
      this.birthDate.months=quote.month;
     
       
      if(quote.name && quote.practice!=undefined && quote.practice!="" && quote.year!= "0" && quote.month!="0"){
      if(this.checkBirthday((this.birthDate))){
      if (this.birthDate.birthstatus===true) {
      this.isLogged = true;
      this.loader();
      try 
      {
        
        var mnth=(parseInt(quote.month));
        var dateMonth=(-mnth)+'-01';
        var bdates=(quote.year+dateMonth+'T00:00:00+00:00');
        let petinfo={BirthDate:bdates,BreedId:quote.BreedId,Gender:quote.Gender,PetName:quote.name,SpeciesId :quote.PetSpeciedId};
        // petinfo.BirthDate= bdates;
        // petinfo.BreedId=quote.BreedId;
        // petinfo.Gender=quote.Gender;
        // petinfo.PetName=quote.name;
        // petinfo.SpeciesId= quote.PetSpeciedId;  
       
        if(this.objectsIds==null && this.objectsIds==undefined || this.objectsIds==""){
          this.objectsIds=0;
        }

        if (
          this.PostalCodes != null &&
          this.PostalCodes != undefined &&
          this.PostalCodes != ""
        ) {
          this.PostalCodes = this.PostalCodes.replace(/\s/g, "");
          var x = this.PostalCodes.slice(0, 3);
          var xy = this.PostalCodes.slice(3); //quote.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/);
          this.PostalCodes = x + " " + xy;
        }

        let Postmodel={email:this.emails,PostCode:this.PostalCodes,petInfo:[petinfo],savedQuoteKey:this.savedQuoteKey,cookieValue:this.queValue,objectId: parseInt(this.objectsIds)} 
        let url= config.api.policyUrl+'GetQuoteValues';
        const response = await axios.post(url,Postmodel)
        if(response.data.result){
          if(response.data.petPolicyList.length>0 && response.data.petPolicyList.length<=5)
          {
           let getPolicyValue = localStorage.getItem("PetPolicyList");
           this.quote = JSON.parse(getPolicyValue);
          
            if(this.quote.petInfo.petPolicyList.length<=5){
               if(this.quote.petInfo.petPolicyList.length>=1){
                 this.indexNumber=0;
                 this.quote.petInfo.petPolicyList[0].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber=index;
                     }
                  })
                  response.data.petPolicyList[0].policyList[this.indexNumber].selected=true;
                }
                if(this.quote.petInfo.petPolicyList.length>=2){
                  this.indexNumber=0;
                 this.quote.petInfo.petPolicyList[1].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber=index;
                     }
                  })
                  response.data.petPolicyList[1].policyList[this.indexNumber].selected=true;
                }
                if(this.quote.petInfo.petPolicyList.length>=3){
                  this.indexNumber=0;
                 this.quote.petInfo.petPolicyList[2].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber=index;
                     }
                  })
                  response.data.petPolicyList[2].policyList[this.indexNumber].selected=true;
                }
                if(this.quote.petInfo.petPolicyList.length>=4){
                  this.indexNumber=0;
                 this.quote.petInfo.petPolicyList[3].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber=index;
                     }
                  })
                  response.data.petPolicyList[3].policyList[this.indexNumber].selected=true;
                }
                if(this.quote.petInfo.petPolicyList.length>=5){
                  this.indexNumber=0;
                  this.quote.petInfo.petPolicyList[4].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber=index;
                     }
                  })
                  response.data.petPolicyList[4].policyList[this.indexNumber].selected=true;
                }
            } 

            this.PetPolicyList=response.data.petPolicyList;
            this.quoteInfo.email=quote.email;
            this.quoteInfo.PostCode=this.PostalCodes;

            this.quote.petInfo.petPolicyList[this.quote.petInfo.petPolicyList.length -1].policyList.forEach((data,index)=>{
              if(data.selected===true){
                this.indexNumber=index;
              }
            })
                
            response.data.petPolicyList[response.data.petPolicyList.length -1].policyList[this.indexNumber].selected=true;

            this.quoteInfo.petInfo=response.data;   

            let quotekey= this.quoteInfo.petInfo.savedQuoteKey;
            this.savedQuoteKey= quotekey;
            let policeslist=this.quoteInfo.petInfo.petPolicyList;
            this.policies= policeslist;
            this.quoteRequest.email=this.quoteInfo.petInfo.email;
            this.quoteRequest.PostCode=this.PostalCodes;
            this.quoteRequest.savedQuoteKey=this.quoteInfo.petInfo.savedQuoteKey;
            this.savequote.email=this.quoteInfo.petInfo.email;
            this.savequote.PostCode=this.PostalCodes;
            this.objectsIds=this.quoteInfo.petInfo.responseId;
            localStorage.setItem("PetPolicyList", JSON.stringify(this.quoteInfo));
            localStorage.setItem("ObjectId", JSON.stringify(this.objectsIds));
            this.$cookies.set("hubObjectId", this.objectsIds, "expiring time");
            this.scrollToTop();
            window.location.reload();
          }
          else{
        this.$toast("We're sorry, but you are only able to enroll up to 5 pets at a time",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
          }
        }
        else{
             this.birthDate.birthstatus=false;
             this.$toast("We're sorry, but pets must be at least 7 weeks of age at the time of enrollment",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
          }
      } catch (error) {
        if (error && error.response && error.response.status === 401) {
          this.isLogged = false;
          this.$toast(error.response,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        } else {
          this.error = error.message;
          this.$toast(error.message,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        }
      } finally {
        this.loading = false;
      }
      }
      }
      }
  }
  else{
    this.showError=false;
         this.$toast("Pet's breed required or should be selected from dropdown",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
  }
    },

    checkIndexNumber(selected){
        return selected ==true;
    },


    async saveQuote() {
      try 
      { 
        
        this.loader(); 
        this.quote.consent1=true;
        let getPolicyValue = localStorage.getItem("PetPolicyList");
        let pet = JSON.parse(getPolicyValue);

      if(pet.petInfo.petPolicyList.length<=5){
        
        if(pet.petInfo.petPolicyList.length>=1){
        let petRefs1= pet.petInfo.petPolicyList[0].petInfo.petRef ? pet.petInfo.petPolicyList[0].petInfo.petRef:"";
        this.pet1_id=petRefs1;
        let petName1= pet.petInfo.petPolicyList[0].petInfo.petName ? pet.petInfo.petPolicyList[0].petInfo.petName:"";
         this.pet1_name=petName1;
        let speciesId1= pet.petInfo.petPolicyList[0].petInfo.speciesId ? pet.petInfo.petPolicyList[0].petInfo.speciesId:"";
         this.pet1_species=speciesId1;
        }

        if(pet.petInfo.petPolicyList.length>=2){
        let petRefs2= pet.petInfo.petPolicyList[1].petInfo.petRef?pet.petInfo.petPolicyList[1].petInfo.petRef:"";
        this.pet2_id=petRefs2;
        let petName2= pet.petInfo.petPolicyList[1].petInfo.petName?pet.petInfo.petPolicyList[1].petInfo.petName:"";
         this.pet2_name=petName2;
        let speciesId2= pet.petInfo.petPolicyList[1].petInfo.speciesId? pet.petInfo.petPolicyList[1].petInfo.speciesId:"";
           this.pet2_species=speciesId2;
        }

        if(pet.petInfo.petPolicyList.length>=3){
        let petRefs3= pet.petInfo.petPolicyList[2].petInfo.petRef?pet.petInfo.petPolicyList[2].petInfo.petRef:"";
        this.pet3_id=petRefs3;
        let petName3= pet.petInfo.petPolicyList[2].petInfo.petName?pet.petInfo.petPolicyList[2].petInfo.petName:"";
         this.pet3_name=petName3;
        let speciesId3= pet.petInfo.petPolicyList[2].petInfo.speciesId? pet.petInfo.petPolicyList[2].petInfo.speciesId:"";
        this.pet3_species=speciesId3;
        }

        if(pet.petInfo.petPolicyList.length>=4){
        let petRefs4= pet.petInfo.petPolicyList[3].petInfo.petRef?pet.petInfo.petPolicyList[3].petInfo.petRef:"";
        this.pet4_id=petRefs4;
        let petName4= pet.petInfo.petPolicyList[3].petInfo.petName?pet.petInfo.petPolicyList[3].petInfo.petName:"";
        this.pet4_name=petName4;
        let speciesId4= pet.petInfo.petPolicyList[3].petInfo.speciesId? pet.petInfo.petPolicyList[3].petInfo.speciesId:"";
        this.pet4_species=speciesId4;
        }

        if(pet.petInfo.petPolicyList.length>=5){
        let petRefs5= pet.petInfo.petPolicyList[4].petInfo.petRef?pet.petInfo.petPolicyList[4].petInfo.petRef:"";
        this.pet5_id=petRefs5;
        let petName5= pet.petInfo.petPolicyList[4].petInfo.petName?pet.petInfo.petPolicyList[4].petInfo.petName:"";
        this.pet5_name=petName5;
        let speciesId5= pet.petInfo.petPolicyList[4].petInfo.speciesId? pet.petInfo.petPolicyList[4].petInfo.speciesId:"";
        this.pet5_species=speciesId5;
        }
        
        if(localStorage.getItem("ObjectId")!=null){
          let objectIds = localStorage.getItem("ObjectId");
          let objectidss = JSON.parse(objectIds);
          this.objectsIds=objectidss;
    }
        else{
          this.objectsIds=0;
        }

        
        if (
          this.PostalCodes != null &&
          this.PostalCodes != undefined &&
          this.PostalCodes != ""
        ) {
          this.PostalCodes = this.PostalCodes.replace(/\s/g, "");
          var x = this.PostalCodes.slice(0, 3);
          var xy = this.PostalCodes.slice(3); //quote.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/);
          this.PostalCodes = x + " " + xy;
        }
         console.log("objectsIds");
        console.log(parseInt(this.objectsIds));
        let Postmodel= {
           "Email": this.emails,
           "QuoteSessionID": 0,
           "SavedQuoteXML": "",
           "SavedQuoteKey":this.savedQuoteKey,
           "SecretQuestionId": 0,
           "PostalCode": this.PostalCodes,
           "Consent1":this.quote.consent1,
           "Consent2":false,
           "pet1_id": this.pet1_id,
           "pet1_name": this.pet1_name,
           "pet1_species": this.pet1_species.toString(),
           "pet2_id": this.pet2_id,
           "pet2_name": this.pet2_name,
           "pet2_species": this.pet2_species.toString(),
           "pet3_id": this.pet3_id,
           "pet3_name":  this.pet3_name,
           "pet3_species": this.pet3_species.toString(),
           "pet4_id": this.pet4_id,
           "pet4_name": this.pet4_name,
           "pet4_species": this.pet4_species.toString(),
           "pet5_id": this.pet5_id,
           "pet5_name":  this.pet5_name,
           "pet5_species":  this.pet5_species.toString(),
           "objectId": parseInt(this.objectsIds),
           "cookieValue":this.queValue
          }

          let url= config.api.policyUrl+'SaveQuote';
          const response = await axios.post(url,Postmodel);
          if(response.data.result){
               this.$toast('Your quote has been saved and sent to you for future reference',{
                styles: {
                  background: 'green'
                },
                type: 'success',
              positionY: 'top',});
         
         }
      }
   } catch (error) {
        if (error && error.response && error.response.status === 401) {
          this.isLogged = false;
          this.$toast(error.response,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        } else {
          this.error = error.message;
          this.$toast(error.message,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        }
      } finally {
        this.loading = false;
      }
    },

    continuetonext()
    {
      try 
      {
        let getPolicyValue = localStorage.getItem("PetPolicyList");
        let uoteInfo = JSON.parse(getPolicyValue);
        let policyList= uoteInfo.petInfo.petPolicyList;
        policyList.forEach(policy =>{ 
          policy.questions= this.Questions;
        });
        uoteInfo.petInfo.petPolicyList=policyList;
        localStorage.setItem("PetPolicyList", JSON.stringify(uoteInfo));
        if(this.policies.length){
          let selectedPlan=0;
          this.policies.forEach(policy =>{ 
            policy.policyList.forEach(plan =>{
              if(plan.selected){
                selectedPlan++;
              }
            });
          });
          if(this.policies.length==selectedPlan){
            this.loader();
             this.showheadDiv = true;
             this.isinfo = false;
              this.ispayment = false;
              this.isQuote = true;
              this.$router.push({name: 'info'});        
          }
          else{
              this.$toast("Please select your pet's plan",{
              styles: {
                background: 'Coral'
              },
              type: 'error',
              positionY: 'top',});
            }
        }
      }catch (error) {
        if (error && error.response && error.response.status === 401) {
          this.isLogged = false;
          this.$toast(error.response,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        } else {
          this.error = error.message;
           this.$toast(error.message,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
        }
      } finally {
        this.loading = false;
      }
    },

     checkBirthday(year,monthss){
      year=this.birthDate.date;
      monthss=parseInt(this.birthDate.months);
      const datee = year.toString();
      var dateofvisit = moment(datee, 'DD-MM-YYYY');
      var today = moment();
      var age=today.diff(dateofvisit, 'years');
      const currentMonth=moment().month()+1;
      const months=currentMonth-monthss;
      this.birthDate.birthstatus=false;

      if (age===0) {
        if(months>0 && months<2){
            if (months>2) {
              this.birthDate.birthstatus=true;
               return this.birthDate.birthstatus;
            }
        }
        }
       if(age >= 0 && age <=14 ){
            if(age==14){
              if (months<=0) {
                 this.showError=false;
                  this.birthDate.birthstatus=true;
                 return this.birthDate.birthstatus;
              }
            }
            if(age<14){
               this.showError=false;
                this.birthDate.birthstatus=true;
               return this.birthDate.birthstatus;
            }
            else{
              this.showError=true;
               this.birthDate.birthstatus=false;
            this.$toast("We're sorry, but pets must be under 14 years of age at the time of enrollment",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
            }
          }
          else{
             this.showError=true;
              this.birthDate.birthstatus=false;
            this.$toast("We're sorry, but pets must be under 14 years of age at the time of enrollment",{
          styles: {
             background: 'Coral'
          },
          positionY: 'top',});
          }
        },

    setEmail(type)
    {
      if(type=='Email'){
        this.savequote.IsEmail=true;
      }else{this.savequote.IsEmail=false;}
    },

    setPlan(productId,key){
      let policeslist=this.quoteInfo.petInfo;
      this.policies[key].policyList.forEach(policy =>{
        if(policy.productId==productId){
          policy.selected=true;
        }else{policy.selected=false;}
      });
      policeslist.petPolicyList = this.policies;
      localStorage.setItem("PetPolicyList", JSON.stringify(this.quoteInfo));
      let quotekey = this.quoteInfo.petInfo.savedQuoteKey;
      this.savedQuoteKey = quotekey;
      let polices_list = this.quoteInfo.petInfo.petPolicyList;
      this.policies= polices_list;
      this.getQuotetotal();
    },

    getyears(){
      let currentYear= new Date().getFullYear();
      this.years=[];
      let i = 0
      for(i = 0; i < 15; i++) {
        let year= currentYear-i;
        this.years.push(year);
      }
    },

    getbread(event){
      this.quote.practice="";
      let SpeciedId = event.target.value;
      this.quote.PetSpeciedId= JSON.parse(SpeciedId);
      const item = {
		   	"PetSpeciedId":this.quote.PetSpeciedId
		  }
      this.getall(item);
    },

    async getQuestions(){	
      try{
        this.Questions=[];
        let url= config.api.url+'UnderwritingQuestionSearch';	
        const response = await axios.post(url,{});	
        const results = response.data.list;
        this.Questions= results.map(question => ({	
          messageIfYes:question.messageIfYes,	
          questionID:question.questionID,
          questionText:question.questionText,
          yesBlocksQuote:question.yesBlocksQuote,
          yesShowsFreeText:question.yesShowsFreeText,
          selected:false
        }))
        this.Questions.push({Id:'',VetName:''},{Id:'',vetDistance:''},{Id:'',text:'Different Than Above',selected:false,other:false});
      }catch (error) {
      console.log(error)
      }finally {
        this.loading = false;
      }
    },

    async getall(search) {
      // this.loading = true;
      try {
        this.isLogged = true;

        let url= config.api.url+'BreedSearch';
        const response = await axios.post(url,search);
        const results = response.data.list;
        this.breads= results.map(post => ({
          Value:post.value,
          BrandId: post.brandId,
          Excluded: post.excluded,
          ExcludedNarrative: post.excludedNarrative,
          Id: post.id,
          MaxAge: post.maxAge,
          MaxAgeWeeks: post.maxAgeWeeks,
          MinAge: post.minAge,
          MinAgeWeeks: post.minAgeWeeks,
          SpeciesId:post.speciesId,
        }))

        this.allbreads=this.breads;
      } catch (error) {
        if (error && error.response && error.response.status === 401) {
          this.isLogged = false;
          this.$toast(error.response,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
          this.allbreads=[];
        } else {
          this.error = error.message;
          this.$toast(error.response,{
          styles: {
             background: 'Coral'
          },
          type: 'error',
          positionY: 'top',});
          this.allbreads=[];
        }
      } finally {
        this.loading = false;
      }
    },

   async removeFromList(key){
      this.loader();
      let policeslist=this.quoteInfo.petInfo;
      this.policies.splice(key, 1);
      policeslist.petPolicyList = this.policies;
      policeslist.petPolicyList.forEach((quotes) => {
        this.petsInfo.push(quotes.petInfo);
      });

        this.petsInfo.forEach((pets) => {
        let petinfo = {
          BirthDate: "",
          BreedId: "",
          Gender: "",
          PetName: "",
          SpeciesId: "",
        };

        this.BirthDate = pets.birthDate;
        this.BreedId = pets.breedId;
        this.Gender = pets.gender;
        this.PetName = pets.petName;
        this.SpeciesId = pets.speciesId;

        petinfo.BirthDate = this.BirthDate;
        petinfo.BreedId = this.BreedId;
        petinfo.Gender = this.Gender;
        petinfo.PetName = this.PetName;
        petinfo.SpeciesId = this.SpeciesId;
        this.petsInfos.push(petinfo);
      });
      let Postmodel={email:this.emails,PostCode:this.PostalCodes,petInfo:this.petsInfos,savedQuoteKey:"",cookieValue:this.queValue,objectId: parseInt(this.objectsIds)} 
      let url= config.api.policyUrl+'GetQuoteValues';
      const response = await axios.post(url,Postmodel);
      if(response.data.result){
          if(response.data.petPolicyList.length>0 && response.data.petPolicyList.length<=5)
          {
          let getPolicyValue = localStorage.getItem("PetPolicyList");
           this.quote = JSON.parse(getPolicyValue);
          if(response.data.petPolicyList.length<=5){
               if(response.data.petPolicyList.length===1){
                 this.indexNumber=0;
                 this.quoteInfo.petInfo.petPolicyList[0].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber=index;
                     }
                  })
                  response.data.petPolicyList[0].policyList[this.indexNumber].selected=true;
                }
                if(response.data.petPolicyList.length===2){
                this.indexNumber=0;
                 this.quoteInfo.petInfo.petPolicyList[0].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber=index;
                     }
                  })
                this.indexNumber1=0;
                 this.quoteInfo.petInfo.petPolicyList[1].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber1=index;
                     }
                  })
                  response.data.petPolicyList[0].policyList[this.indexNumber].selected=true;
                  response.data.petPolicyList[1].policyList[this.indexNumber1].selected=true;
                }
                if(response.data.petPolicyList.length===3){
                this.indexNumber=0;
                 this.quoteInfo.petInfo.petPolicyList[0].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber=index;
                     }
                  })
                this.indexNumber1=0;
                 this.quoteInfo.petInfo.petPolicyList[1].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber1=index;
                     }
                  })
                  this.indexNumber2=0;
                 this.quoteInfo.petInfo.petPolicyList[2].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber2=index;
                     }
                  })
                  response.data.petPolicyList[0].policyList[this.indexNumber].selected=true;
                  response.data.petPolicyList[1].policyList[this.indexNumber1].selected=true;
                  response.data.petPolicyList[2].policyList[this.indexNumber2].selected=true;
                }
                if(response.data.petPolicyList.length===4){
                   this.indexNumber=0;
                 this.quoteInfo.petInfo.petPolicyList[0].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber=index;
                     }
                  })
                this.indexNumber1=0;
                 this.quoteInfo.petInfo.petPolicyList[1].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber1=index;
                     }
                  })
                  this.indexNumber2=0;
                 this.quoteInfo.petInfo.petPolicyList[2].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber2=index;
                     }
                  })
                  this.indexNumber3=0;
                 this.quoteInfo.petInfo.petPolicyList[3].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber3=index;
                     }
                  })
                  response.data.petPolicyList[0].policyList[this.indexNumber].selected=true;
                  response.data.petPolicyList[1].policyList[this.indexNumber1].selected=true;
                  response.data.petPolicyList[2].policyList[this.indexNumber2].selected=true;
                  response.data.petPolicyList[3].policyList[this.indexNumber3].selected=true;
                }
                if(response.data.petPolicyList.length===5){
                    this.indexNumber=0;
                 this.quoteInfo.petInfo.petPolicyList[0].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber=index;
                     }
                  })
                this.indexNumber1=0;
                 this.quoteInfo.petInfo.petPolicyList[1].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber1=index;
                     }
                  })
                  this.indexNumber2=0;
                 this.quoteInfo.petInfo.petPolicyList[2].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber2=index;
                     }
                  })
                  this.indexNumber3=0;
                 this.quoteInfo.petInfo.petPolicyList[3].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber3=index;
                     }
                  })
                  this.indexNumber4=0;
                  this.quoteInfo.petInfo.petPolicyList[4].policyList.forEach((data,index)=>{
                    if(data.selected===true){
                       this.indexNumber4=index;
                     }
                  })
                  response.data.petPolicyList[0].policyList[this.indexNumber].selected=true;
                  response.data.petPolicyList[1].policyList[this.indexNumber1].selected=true;
                  response.data.petPolicyList[2].policyList[this.indexNumber2].selected=true;
                  response.data.petPolicyList[3].policyList[this.indexNumber3].selected=true;
                  response.data.petPolicyList[4].policyList[this.indexNumber4].selected=true;
                }
            }   
                          
            this.PetPolicyList=response.data.petPolicyList;
            this.quoteInfo.PostCode=this.PostalCodes;
            this.quoteInfo.petInfo=response.data;

            let quotekey= this.quoteInfo.petInfo.savedQuoteKey;
            this.savedQuoteKey= quotekey;
            let policeslist=this.quoteInfo.petInfo.petPolicyList;
            this.policies= policeslist;
            this.quoteRequest.email=this.quoteInfo.petInfo.email;
            this.quoteRequest.PostCode=this.PostalCodes;
            this.quoteRequest.savedQuoteKey=this.quoteInfo.petInfo.savedQuoteKey;
            this.savequote.email=this.quoteInfo.petInfo.email;
            this.savequote.PostCode=this.PostalCodes;
            this.objectsIds=this.quoteInfo.petInfo.responseId;
            localStorage.setItem("PetPolicyList", JSON.stringify(this.quoteInfo));
            localStorage.setItem("ObjectId", JSON.stringify(this.objectsIds));
            this.scrollToTop();
            window.location.reload();
          }
        if (this.quoteInfo.petInfo.petPolicyList.length==0) {
        this.$router.push({ name: "Home" });
        localStorage.removeItem("InfoDetails");
        localStorage.removeItem("PetPolicyList");
        localStorage.removeItem("PaymentAmount");
        localStorage.removeItem("PostalCode");
        localStorage.removeItem("BuyPolicyData");
      }
    } 
  },



    setBreadcode(bread){
     this.quote.BreedId=bread.Id;
     this.quote.practice=bread.Value;
     this.showDiv = false;
    },
    
     breadsearch() {
		  if(this.quote.practice.length >=1)
		  {
        const item = {
			    "PetSpeciedId":this.quote.PetSpeciedId,
          "Filter":this.quote.practice      
		    }
        this.getall(item);
      }
      else{
         const item = {
			    "PetSpeciedId":this.quote.PetSpeciedId,
          "Filter":this.quote.practice      
		    }
        this.getall(item);
      }
    },

    showlist() {
      this.breads;
      this.showDiv = true;
    },
    hidelist() {
      this.showDiv = false;
    },
    loader() {
      this.selected='circular';
      this.code=`<loader object="${this.styling.objectcolor}" color1="${this.styling.animationcolor}" color2="${this.styling.animationcolor2}" size="${this.styling.size}" speed="${this.styling.speed}" bg="${this.styling.bgcolor}" objectbg="${this.styling.objectbg}" opacity="${this.styling.opacity}" disableScrolling="${this.styling.disableScrolling}" name="${this.selected}"></loader>`
      setTimeout(() => {
        this.selected='';
      }, this.value*3000);
    },

   getQuotetotal() {
      let getPolicyValue = localStorage.getItem("PetPolicyList");
      let quoteInfo = JSON.parse(getPolicyValue);
      let policeslist = quoteInfo.petInfo.petPolicyList;
      this.QuoteTotal=0;
      policeslist.forEach((policy) => {
      policy.policyList.forEach((policyes)=>{
       if(policyes.selected===true){
       this.QuoteTotal +=policyes.recurringMonthly;
      }
      });
  });
},
    
 clearErrors(){
  this.errors=[];
  this.quote.practice="";
  this.quote.name="";
  this.quote.month="0";
  this.quote.year="0";
  this.quote.PetSpeciedId=42989;
  this.quote.Gender='M';
  window.location.reload();
},

}
}
</script>
<style scoped>
</style>