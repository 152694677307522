//import Vue from "vue";
import axios from "axios";

// const client = axios.create({
//   baseURL: "http://wdc1.acapqa.net/trupanionApi/api/",
//   json: true,
// });
import { client as config } from '../config'

const api = {
  GetBreads(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: config.api.url + url,
        data,
        headers: {
          'Content-Type': 'application/json',
          //Authorization: `Bearer ${idToken}`,
        },
      }).then(res => {
        if (res.data) {
          if (res.data.List) {
            return res.data.List;
          }
          resolve(res.data.List)

        } else {
          console.error(res.data)
          reject(new Error('No data found'))
        }
      }).catch(err => {
        reject((err.response || {}).data || err.message || err)
      })
    })
  },

  async getquote(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: config.api.policyUrl + url,
        data,
        headers: {
          'Content-Type': 'application/json',
          //Authorization: `Bearer ${idToken}`,
        },
      }).then(res => {
        if (res.data) {
          if (res.data.List) {
            return res.data.List;
          }
          resolve(res.data.List)

        } else {
          console.error(res.data)
          reject(new Error('No data found'))
        }
      }).catch(err => {
        // debugger;
        reject((err.response || {}).data || err.message || err)
      })
    })

  }

}

export default api

// export default {
//   async execute(method, resource, data) {
//     //const accessToken = await Vue.prototype.$auth.getAccessToken();
//     return client({
//       method,
//       url: resource,
//       data,
//       //headers: {Authorization: `Bearer ${accessToken}`,},
//     }).then((req) => {
//       return req.data;
//     });
//   },
//   getAll() {
//     return this.execute("get");
//   },
//   getById(id) {
//     return this.execute("get", `/${id}`);
//   },
//   getlist(path, data) {
//     return this.execute("POST", path, data);
//   },
//   update(id, data) {
//     return this.execute("put", `/${id}`, data);
//   },


// };
